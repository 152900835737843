import { apiGetAuth, apiPostAuth } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';

const state = {
  brandings: [],
  branding: null,
  total: 0,
  loading: false,
  error: null,
};

const mutations = {
  SET_PO_BRANDINGS(state, brandings) {
    state.brandings = brandings;
  },
  SET_PO_BRANDING(state, branding) {
    state.branding = branding;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async getPoBranding({ commit }, { limit, page, search }) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.APPROVAL_PO_BRANDING}?limit=${limit}&page=${page}&search=${search}`);
      if (response.data.status_code === 200) {

        commit('SET_PO_BRANDINGS', response.data.data.data);
        commit('SET_TOTAL', response.data.data.total);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createPoBranding({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await apiPostAuth(API_MASTER.CREATE_APPROVAL_PO_BRANDING, payload);
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getApprovalPoBrandingById({ commit }, po_number) { 
    commit('SET_LOADING', true); 
    
    try { 
      const response = await apiGetAuth(`${API_MASTER.DETAIL_APPROVAL_PO_BRANDING}?po_number=${po_number}`);
      if (response.data.status_code === 200) { 
        return response.data.data 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  setSelectedPoBranding({ commit }, poBranding) {
    commit('SET_PO_BRANDING', poBranding);
  }

};

const getters = {
  getPoBrandings(state) {
    return state.brandings;
  },
  getPoBranding(state) {
    return state.branding;
  },
  getTotalPoBrandings(state) {
    return state.total;
  },
  isLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
