const METHOD = {
    GET: 'get',
    PATCH: 'patch',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete'
}

const PPN = {
    code: 'PPN_11%',
    value: 11
}

export {
    METHOD,
    PPN
}