import axios from 'axios';
import { METHOD } from './constant';
import { getUserToken, handleLogout } from '@/utils/auth';

// Create an Axios instance
const apiInstance = axios.create();

// Add a response interceptor
apiInstance.interceptors.response.use(
  function (response) {
    if (response?.status === 401 && response?.data?.message === 'account_already_logout') {
      handleLogout(response);
    }
    return response;
  },
  function (error) {
    if (error?.response?.status === 401 && error?.response?.data?.message === 'account_already_logout') {
      handleLogout(error.response);
    }

    return Promise.reject(error);
  }
);

export const apiGetNonAuth = (URL, params) => axios({
  url: URL,
  method: METHOD.GET,
  params
});

export const apiPostNonAuth = (URL, data) => axios({
  url: URL,
  method: METHOD.POST,
  data
});

export const apiGetAuth = (URL, params) => apiInstance({
  url: URL,
  method: METHOD.GET,
  params,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiGetAuthWithCustomHeadersResponseType = (URL, params) => apiInstance({
  url: URL,
  method: METHOD.GET,
  params,
  headers: {
    "Content-Type": "application/octet-stream",
    Authorization: getUserToken()
  },
  responseType: 'arraybuffer'
});

export const apiPostAuth = (URL, data) => axios({
  url: URL,
  method: METHOD.POST,
  data,
  headers: {
    Authorization: getUserToken()
  }
})

export const apiPostAuthWithImage = (URL, data) => {
  // If the data is supposed to be sent as FormData, convert it.
  let formData = data;

  // Check if payload is not already a FormData instance
  if (!(data instanceof FormData)) {
    formData = new FormData();
    // Assuming data is an object, append each key-value pair to FormData
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return apiInstance({
    url: URL,
    method: METHOD.POST,
    data: formData,
    headers: {
      Authorization: getUserToken(),
      // Do not set 'Content-Type' manually for FormData, let the browser handle it
    }
  });
};

export const apiPutAuthWithImage = (URL, data) => {
  // If the data is supposed to be sent as FormData, convert it.
  let formData = data;

  // Check if payload is not already a FormData instance
  if (!(data instanceof FormData)) {
    formData = new FormData();
    // Assuming data is an object, append each key-value pair to FormData
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });
  }

  return apiInstance({
    url: URL,
    method: METHOD.PUT,
    data: formData,
    headers: {
      Authorization: getUserToken(),
      // Do not set 'Content-Type' manually for FormData, let the browser handle it
    }
  });
};

export const apiPostAuthWithResponseType = (URL, responseType, data) => apiInstance({
  url: URL,
  method: METHOD.POST,
  data,
  headers: {
    Authorization: getUserToken()
  },
  responseType
});

export const apiPutAuth = (URL, data) => {
  // If the data is supposed to be sent as FormData, convert it.
  let formData = data;

  // Check if payload is not already a FormData instance
  // if ((data instanceof FormData)) {
  //   formData = new FormData();
  //   // Assuming data is an object, append each key-value pair to FormData
  //   Object.keys(data).forEach(key => {
  //     formData.append(key, data[key]);
  //   });
  // }

  return apiInstance({
    url: URL,
    method: METHOD.PUT,
    data: formData,
    headers: {
      Authorization: getUserToken()
    }
  })
};

export const apiPatchAuth = (URL, data) => apiInstance({
  url: URL,
  method: METHOD.PATCH,
  data,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiDeleteAuth = (URL, data) => axios({
  url: URL,
  method: METHOD.DELETE,
  data,
  headers: {
    Authorization: getUserToken()
  }
});

export const apiPostForgot = (URL, token, data) => axios({
  url: URL,
  method: METHOD.POST,
  data,
  headers: {
    Authorization: 'Bearer '+token
  }
})