import { apiGetAuth, apiPostAuth, apiPutAuth } from '@/utils/api'; 
import { API_MASTER } from '@/utils/api-url'; 

const state = { 
  budgets: [], 
  budget: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_BUDGETS(state, budgets) { 
    state.budgets = budgets; 
  }, 
  SET_BUDGET(state, budget) { 
    state.budget = budget; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getBudget({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.BUDGET}?limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        commit('SET_BUDGETS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createBudget({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuth(API_MASTER.CREATE_BUDGET, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateBudget({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPutAuth(API_MASTER.UPDATE_BUDGET, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getBudgetById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.BUDGET}/${id}`); 
      if (response.data.status_code === 200) { 
        commit('SET_BUDGET', response.data.data); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  // Action for uploading file
  async uploadFile({ commit }, { file }) {
    commit('SET_LOADING', true);
    try {
      const formData = new FormData();
      formData.append('file', file); // Append the file to FormData
      
      const response = await apiPostAuth(API_MASTER.UPLOAD_BUDGET, formData, {
        headers: { 'Content-Type': 'multipart/form-data' } // Set correct headers for file upload
      });
      
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  }
}; 

const getters = { 
  getBudgets(state) { 
    return state.budgets; 
  }, 
  getBudget(state) { 
    return state.budget; 
  }, 
  getTotalBudgets(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
