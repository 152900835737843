import { apiGetAuth, apiPostAuth } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';

const state = {
  approval_invoice_brandings: [],
  approval_invoice_branding: null,
  total: 0,
  loading: false,
  error: null,
};

const mutations = {
  SET_APPROVAL_INVOICE_BRANDINGS(state, approval_invoice_brandings) {
    state.approval_invoice_brandings = approval_invoice_brandings;
  },
  SET_APPROVAL_INVOICE_BRANDING(state, approval_invoice_branding) {
    state.approval_invoice_branding = approval_invoice_branding;
  },
  SET_TOTAL(state, total) {
    state.total = total;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  }
};

const actions = {
  async getApprovalInvoiceBranding({ commit }, { limit, page, search }) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.APPROVAL_INVOICE_BRANDING}?limit=${limit}&page=${page}&search=${search}`);
      if (response.data.status_code === 200) {

        commit('SET_APPROVAL_INVOICE_BRANDINGS', response.data.data.data);
        commit('SET_TOTAL', response.data.data.total);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async createApprovalInvoiceBranding({ commit }, payload) {
    commit('SET_LOADING', true);
    try {
      const response = await apiPostAuth(API_MASTER.CREATE_APPROVAL_INVOICE_BRANDING, payload);
      if (response.data.status_code === 200) {
        return response.data.message;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  setSelectedApprovalInvoiceBranding({ commit }, poBranding) {
    commit('SET_APPROVAL_INVOICE_BRANDING', poBranding);
  }

};

const getters = {
  getApprovalInvoiceBrandings(state) {
    return state.approval_invoice_brandings;
  },
  getApprovalInvoiceBranding(state) {
    return state.approval_invoice_branding;
  },
  getTotalApprovalInvoiceBrandings(state) {
    return state.total;
  },
  isLoading(state) {
    return state.loading;
  },
  getError(state) {
    return state.error;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
