import { apiGetAuth, apiPostAuth, apiDeleteAuth } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';

const state = {
    brandings: [],
    branding: null,
    advance_compensations: [],
    advance_compensation: null,
    total: 0,
    loading: false,
    error: null
};

const mutations = {
    SET_PO_BRANDINGS(state, brandings) {
        state.brandings = brandings;
    },
    SET_PO_BRANDING(state, branding) {
        state.branding = branding;
    },
    SET_ADVANCE_COMPENSATIONS(state, advance_compensations) {
        state.advance_compensations = advance_compensations;
    },
    SET_ADVANCE_COMPENSATION(state, advance_compensation) {
        state.advance_compensation = advance_compensation;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async getPoBranding({ commit }, { limit, page, search, status }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.PO_BRANDING}?limit=${limit}&page=${page}&search=${search}&status=${status}`);
            if (response.data.status_code === 200) {
                commit('SET_PO_BRANDINGS', response.data.data.data);
                commit('SET_TOTAL', response.data.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getAdvanceCompensation({ commit }, { limit, page, search }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.ADVANCE_COMPENSATION}?limit=${limit}&page=${page}&search=${search}`);
            if (response.data.status_code === 200) {
                commit('SET_ADVANCE_COMPENSATIONS', response.data.data.data);
                commit('SET_TOTAL', response.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },


    async createAdvanceCompensation({ commit }, payload) {
        commit('SET_LOADING', true);
        try {
            const response = await apiPostAuth(API_MASTER.CREATE_ADVANCE_COMPENSATION, payload);
            console.log(response);

            if (response.data.status_code === 200) {
                return response.data.message;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getAdvanceCompensationById({ commit }, po_number) {
        commit('SET_LOADING', true);

        try {
            const response = await apiGetAuth(`${API_MASTER.DETAIL_ADVANCE_COMPENSATION}?po_number=${po_number}`);
            if (response.data.status_code === 200) {
                return response.data.data
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async deleteAdvanceCompensation({ commit }, poNumber) {
        console.log('Deleting PO Branding:', poNumber);
        commit('SET_LOADING', true);
        try {
            const response = await apiDeleteAuth(`${API_MASTER.DELETE_ADVANCE_COMPENSATION}?po_number=${poNumber}`);
            console.log(response)
            if (response.data.status_code === 200) {
                const updatedAdvanceCompensations = state.advance_compensations.filter(
                    compensation => compensation.po_number !== poNumber
                );
                commit('SET_ADVANCE_COMPENSATIONS', updatedAdvanceCompensations);
                return response.data.message;
            }
        } catch (error) {
            console.error('Error deleting Advance Compensation:', error.message);
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    setSelectedAdvanceCompensation({ commit }, poEvent) {
        commit('SET_ADVANCE_COMPENSATION', poEvent);
    }

};

const getters = {
    getPoBrandings(state) {
        return state.brandings;
    },
    getPoBranding(state) {
        return state.branding;
    },
    getAdvanceCompensations(state) {
        return state.advance_compensations;
    },
    getAdvanceCompensation(state) {
        return state.advance_compensation;
    },
    getTotalAdvanceCompensations(state) {
        return state.total;
    },
    isLoading(state) {
        return state.loading;
    },
    getError(state) {
        return state.error;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
