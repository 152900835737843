<template>
  <router-view />
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
/* Global styles */
html, body, #app {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

* {
  box-sizing: border-box;
}

.form-control {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ck-editor {
  border-radius: 8px !important;
}
.ck-editor__editable {
  min-height: 150px;
}
.ck-column-resize_disabled {
  background-color: #F0F1F4 !important;
}
.ckeditor-disabled .ck-editor__editable {
   background-color: #F8F8F8 !important;
   pointer-events: none;
 }
 .ck.ck-editor__top .ck-sticky-panel .ck-toolbar {
  background-color: #F0F1F4 !important;
 }
  .badge {
    cursor: pointer !important;
  }
</style>
