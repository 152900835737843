import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from "./store"
import 'admin-lte/dist/css/adminlte.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'admin-lte/dist/js/adminlte.min.js';
import 'admin-lte/plugins/fontawesome-free/css/all.min.css';
import 'bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css';
import 'bootstrap-datepicker';
import 'popper.js';
import $ from 'jquery';
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.css';
import ToastPlugin from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-bootstrap.css';

window.$ = $; // Attach jQuery to the global window object

const app = createApp(App).use(store).use(router).use(ToastPlugin, {
    position: 'top'
});
app.component('MultiSelect', Multiselect);
app.mount("#app");
