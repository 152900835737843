import { apiGetAuth, apiPostAuth } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';

const state = {
    settlement_compensations: [],
    settlement_compensation: null,
    total: 0,
    loading: false,
    error: null
};

const mutations = {
    SET_SETTLEMENT_COMPENSATIONS(state, settlement_compensations) {
        state.settlement_compensations = settlement_compensations;
    },
    SET_SETTLEMENT_COMPENSATION(state, settlement_compensation) {
        state.settlement_compensation = settlement_compensation;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async getSettlementCompensation({ commit }, { limit, page, search }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.SETTLEMENT_COMPENSATION}?limit=${limit}&page=${page}&search=${search}`);
            if (response.data.status_code === 200) {
                commit('SET_SETTLEMENT_COMPENSATIONS', response.data.data.data);
                commit('SET_TOTAL', response.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async getSettlementCompensationById({ commit }, po_number) {
        commit('SET_LOADING', true);

        try {
            const response = await apiGetAuth(`${API_MASTER.DETAIL_SETTLEMENT_COMPENSATION}?po_number=${po_number}`);
            if (response.data.status_code === 200) {
                return response.data.data
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async createSettlementCompensation({ commit }, payload) {
        commit('SET_LOADING', true);
        try {
            const response = await apiPostAuth(API_MASTER.CREATE_SETTLEMENT_COMPENSATION, payload);
            console.log(response);

            if (response.data.status_code === 200) {
                return response.data.message;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },

    setSelectedSettlementCompensation({ commit }, poEvent) {
        commit('SET_SETTLEMENT_COMPENSATION', poEvent);
    }

};

const getters = {
    getSettlementCompensations(state) {
        return state.settlement_compensations;
    },
    getSettlementCompensation(state) {
        return state.settlement_compensation;
    },
    getTotalSettlementCompensations(state) {
        return state.total;
    },
    isLoading(state) {
        return state.loading;
    },
    getError(state) {
        return state.error;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
