import { apiGetAuth, apiPostAuthWithImage, apiPutAuth } from '@/utils/api';
import { API_MASTER } from '@/utils/api-url';

const state = {
    invoice_brandings: [],
    invoice_branding: null,
    total: 0,
    loading: false,
    error: null
};

const mutations = {
    SET_INVOICE_BRANDINGS(state, invoice_brandings) {
        state.invoice_brandings = invoice_brandings;
    },
    SET_INVOICE_BRANDING(state, invoice_branding) {
        state.invoice_branding = invoice_branding;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },
    SET_LOADING(state, isLoading) {
        state.loading = isLoading;
    },
    SET_ERROR(state, error) {
        state.error = error;
    }
};

const actions = {
    async getInvoiceBranding({ commit }, { limit, page, search }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.INVOICE_BRANDING}?limit=${limit}&page=${page}&search=${search}`);
            if (response.data.status_code === 200) {

                commit('SET_INVOICE_BRANDINGS', response.data.data.data);
                commit('SET_TOTAL', response.data.data.total);
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },

    async createInvoiceBranding({ commit }, payload) {
        commit('SET_LOADING', true);
        try {
            const response = await apiPostAuthWithImage(API_MASTER.CREATE_INVOICE_BRANDING, payload);
            console.log('post', response);
            if (response.data.status_code === 200) {
                return response.data.message;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async updateInvoiceBranding({ commit }, payload) {
        commit('SET_LOADING', true);
        try {
            const response = await apiPutAuth(API_MASTER.UPDATE_INVOICE_BRANDING(payload.id), payload);
            if (response.data.status_code === 200) {
                return response.data.message;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async getInvoiceBrandingById({ commit }, id) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.INVOICE_BRANDING}/detail?po_number=${id}`);
            if (response.data.status_code === 200) {
                commit('SET_INVOICE_BRANDING', response.data.data);
                return response.data.data;
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
            throw error;
        } finally {
            commit('SET_LOADING', false);
        }
    },
    async getItemAttachments({ commit }) {
        commit('SET_LOADING', true);
        try {
            const response = await apiGetAuth(`${API_MASTER.ITEM_ATTACHMENT}?limit=100&page=1`);
            if (response.data.status_code === 200) {
                return response.data.data.data.map(item => ({
                    id: item.item_code,
                    value: item.item_code,
                    name: item.item_name,
                }));
            } else {
                throw new Error(response.data.message);
            }
        } catch (error) {
            commit('SET_ERROR', error.message);
        } finally {
            commit('SET_LOADING', false);
        }
    },
};

const getters = {
    getInvoiceBrandings(state) {
        return state.invoice_brandings;
    },
    getInvoiceBranding(state) {
        return state.invoice_bradning;
    },
    getTotalInvoiceEvents(state) {
        return state.total;
    },
    isLoading(state) {
        return state.loading;
    },
    getError(state) {
        return state.error;
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
