import { apiGetAuth } from '@/utils/api';
import { API_AUTH } from '@/utils/api-url';
import { getUserToken, setCookieProfile, getUserProfile } from '@/utils/auth';

const state = {
  profile: getUserProfile() || null, // Get profile from cookie
  roleMenu: [], // Stores the user's role menu
  loading: false,
  error: null
};

const mutations = {
  SET_PROFILE(state, profile) {
    state.profile = profile;
    setCookieProfile(JSON.stringify(profile)); // Save profile to cookie
  },
  SET_ROLE_MENU(state, menu) {
    state.roleMenu = menu;
  },
  SET_LOADING(state, isLoading) {
    state.loading = isLoading;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  RESET_STATE(state) {
    state.profile = null;
    state.roleMenu = [];
    state.loading = false;
    state.error = null;
  }
};

const actions = {
  async fetchUserProfile({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const token = getUserToken();
      if (!token) {
        throw new Error('No token found');
      }

      const response = await apiGetAuth(API_AUTH.PROFILE);
      if (response.data.status_code === 200) {
        const profile = response.data.data;
        commit('SET_PROFILE', profile);
        return profile;
      } else {
        throw new Error(response.data.message || 'Failed to fetch user profile');
      }
    } catch (error) {
      commit('SET_ERROR', 'Failed to fetch user profile');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getRoleMenu({ commit }) {
    commit('SET_LOADING', true);
    commit('SET_ERROR', null);
    try {
      const response = await apiGetAuth(API_AUTH.ROLE_MENU);
      if (response.data.status_code === 200) {
        const menu = response.data.data;
        commit('SET_ROLE_MENU', menu);
        return menu;
      } else {
        throw new Error(response.data.message || 'Failed to fetch role menu');
      }
    } catch (error) {
      commit('SET_ERROR', 'Failed to fetch role menu');
      throw error;
    } finally {
      commit('SET_LOADING', false);
    }
  },

  resetProfileState({ commit }) {
    commit('RESET_STATE');
  }
};

const getters = {
  getUserProfile: (state) => state.profile,
  getUserRoleMenu: (state) => state.roleMenu,
  isProfileLoading: (state) => state.loading,
  getProfileError: (state) => state.error,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
