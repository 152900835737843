import { apiGetAuth, apiPostAuthWithImage, apiPutAuthWithImage } from '@/utils/api'; 
import { API_AUTH, API_MASTER } from '@/utils/api-url'; 

const state = { 
  programs: [], 
  program: null, 
  promos: [], 
  promo: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_PROGRAMS(state, programs) { 
    state.programs = programs; 
  }, 
  SET_PROGRAM(state, program) { 
    state.program = program; 
  }, 
  SET_PROMOS(state, promos) { 
    state.promos = promos; 
  }, 
  SET_PROMO(state, promo) { 
    state.promo = promo; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getProgram({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.PROGRAM_PROMO}?type=program&limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_PROGRAMS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getPromo({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.PROGRAM_PROMO}?type=promo&limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_PROMOS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createProgram({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuthWithImage(API_MASTER.CREATE_PROGRAM_PROMO, payload); 
      
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateProgram({ commit }, { payload, id, type }) { 
    console.log(id);
    
    commit('SET_LOADING', true); 
    try { 
      
      console.log(payload);
      const response = await apiPutAuthWithImage(`${API_MASTER.CREATE_PROGRAM_PROMO}/${type}/${id}`, payload); 
      
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getProgramById({ commit }, data) { 
    console.log(API_MASTER.DETAIL_PROGRAM_PROMO);
    console.log(data);
    
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.DETAIL_PROGRAM_PROMO}/${data.type}/${data.id}`); 
      if (response.data.status_code === 200) { 
        commit('SET_PROGRAM', response.data.data); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getModule({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.MODULE}`); 
      if (response.data.status_code === 200) {
        // Return the mapped module data
        return response.data.data.map(module => ({
          id: module.id,
          value: module.id,
          name: module.module_name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getParent({ commit }, { module_id }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.PARENT}?module_id=${module_id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data.map(parent => ({
          id: parent.id,
          value: parent.id,
          name: parent.module_name 
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getDepartment({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.DEPARTMENT}`); 
      if (response.data.status_code === 200) {
        // Return the mapped department data
        return response.data.data.map(department => ({
          id: department.id,
          value: department.id,
          name: department.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getRegion({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.REGION}`); 
      if (response.data.status_code === 200) {
        // Return the mapped region data
        return response.data.data.map(region => ({
          id: region.id,
          value: region.id,
          name: region.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getSubRegion({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.SUB_REGION}`); 
      if (response.data.status_code === 200) {
        // Return the mapped sub_region data
        return response.data.data.map(sub_region => ({
          id: sub_region.id,
          value: sub_region.id,
          name: sub_region.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getSubRegionById({ commit }, id) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.SUB_REGION}?id=${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped sub_region data
        return response.data.data.map(sub_region => ({
          id: sub_region.id,
          value: sub_region.id,
          name: sub_region.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBranches({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`); 
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBranchesById({ commit }, id) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}?id=${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getRoles({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.ROLES}`); 
      if (response.data.status_code === 200) {
        // Return the mapped roles data
        return response.data.data.map(roles => ({
          id: roles.id,
          value: roles.id,
          name: roles.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

}; 

const getters = { 
  getPrograms(state) { 
    return state.programs; 
  }, 
  getProgram(state) { 
    return state.program; 
  }, 
  getTotalPrograms(state) { 
    return state.total; 
  },
  getPromos(state) { 
    return state.promos; 
  }, 
  getPromo(state) { 
    return state.promos; 
  }, 
  getTotalPromos(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
