<template>
  <div class="login-container">
    <div class="login-left">
      <img class="logo-kalbe" src="@/assets/logo/logo-kalbe.png" alt="">
      <div v-if="!userInactive" class="login-left-content">
        <!-- Login form -->
        <img class="logo-orbit mx-auto d-block" src="@/assets/logo/logo.png" alt="">
        <h2 class="text-center login-headline">Login</h2>
        <p class="text-center">Masukkan email dan password Anda untuk mengakses dashboard.</p>
        <form @submit.prevent="login" class="login-form">
          <!-- Email input -->
          <div class="mb-4">
            <label for="email" class="form-label">Email</label>
            <input 
              v-model="email" 
              type="text" 
              :class="{'is-invalid': emailError}" 
              class="form-control" 
              id="email" 
              placeholder="Masukkan Alamat Email Akun Anda" 
            >
            <span v-if="emailError" class="invalid-input">{{ emailError }}</span>
          </div>
          <!-- Password input -->
          <div class="mb-4">
            <label for="password" class="form-label">Password</label>
            <div class="input-group">
              <input 
                v-model="password" 
                :class="{'is-invalid': passwordError}" 
                :type="showPassword ? 'text' : 'password'" 
                class="form-control password-input" 
                id="password" 
                placeholder="Masukkan Password Akun Anda" 
                @input="handleInput"
              >
              <span class="input-group-text show-pass" @click="togglePasswordVisibility">
                <i :class="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
              </span>
            </div>
            <span v-if="passwordError" class="invalid-input">{{ passwordError }}</span>
            <div class="text-end">
              <router-link to="/forgot-password" class="small forgot">Forgot Password?</router-link>
            </div>
          </div>
          <button type="submit" class="btn w-100">Login</button>
        </form>
      </div>

      <!-- New Password Form when user is inactive -->
      <div v-else class="login-left-content">
        <h2 class="text-center">Atur Password Baru</h2>
        <form @submit.prevent="setNewPassword" class="login-form">
          <div class="mb-4">
            <label for="newPassword" class="form-label">Password Baru</label>
            <div class="input-group">
              <input 
                v-model="newPassword" 
                class="form-control set-password" 
                :type="showNewPassword ? 'text' : 'password'" 
                id="newPassword" 
                placeholder="Masukkan Password Akun Anda"
                :class="{'is-invalid': setNewPasswordError}" 
              >
              <span class="input-group-text show-pass" @click="toggleNewPasswordVisibility">
                <i :class="showNewPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
              </span>
            </div>
          </div>
          <div class="mb-4">
            <label for="confirmPassword" class="form-label">Konfirmasi Password</label>
            <div class="input-group">
              <input 
                v-model="confirmPassword" 
                class="form-control set-password" 
                :type="showConfirmPassword ? 'text' : 'password'" 
                id="confirmPassword" 
                placeholder="Masukkan Konfirmasi Password Akun Anda"
                :class="{'is-invalid': setNewPasswordError}" 
              >
              <span class="input-group-text show-pass" @click="toggleConfirmPasswordVisibility">
                <i :class="showConfirmPassword ? 'bi bi-eye' : 'bi bi-eye-slash'"></i>
              </span>
              <span v-if="setNewPasswordError" class="invalid-input" style="margin-top: 42px">{{ setNewPasswordError }}</span>
            </div>
          </div>
          <button type="submit" class="btn w-100">Login</button>
        </form>
      </div>
    </div>
    <!-- Right side banner -->
    <div class="login-right">
      <div class="login-right-content">
        <img src="@/assets/banner/login-inner-banner.png" alt="Login Banner" class="img-fluid">
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
  data() {
    return {
      email: '',
      password: '',
      showPassword: false, // Control password visibility
      showNewPassword: false, // Control password visibility
      showConfirmPassword: false, // Control password visibility
      setNewPasswordError: '',
      emailError: '', // Error message for email
      passwordError: '', // Error message for password
      userInactive: false, // This flag controls which form to show
      newPassword: '', // New password input for inactive users
      confirmPassword: '', // Confirm password input
      loginRes: []
    };
  },
  computed: {
    ...mapState({
      isAuthenticated: state => state.auth.isAuthenticated,
      loading: state => state.auth.loading,
      error: state => state.auth.error,
      profile: state => state.auth.profile
    })
  },
  methods: {
    ...mapActions({
      loginUser: 'auth/login',
      changePassword: 'auth/changePassword'
    }),
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword; // Toggle between showing and hiding the password
      this.showNewPassword = !this.showNewPassword; // Toggle between showing and hiding the password
      this.showConfirmPassword = !this.showConfirmPassword; // Toggle between showing and hiding the password
    },
    toggleNewPasswordVisibility() {
      this.showNewPassword = !this.showNewPassword; // Toggle between showing and hiding the password
    },
    toggleConfirmPasswordVisibility() {
      this.showConfirmPassword = !this.showConfirmPassword; // Toggle between showing and hiding the password
    },
    handleInput() {
      // Clear error messages when user starts typing
      this.emailError = '';
      this.passwordError = '';
    },
    validateEmail(email) {
      // Simple email format validation
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    },
    async login() {
      let hasError = false;

      // Validate email
      if (!this.email) {
        this.emailError = 'Masukkan alamat email akun Anda';
        hasError = true;
      } else if (!this.validateEmail(this.email)) {
        this.emailError = 'Harap masukkan format email yang sesuai';
        hasError = true;
      } else {
        this.emailError = ''; // Clear email error if email is valid
      }

      // Validate password
      if (!this.password) {
        this.passwordError = 'Masukkan password untuk login akun Anda';
        hasError = true;
      } else {
        this.passwordError = ''; // Clear password error if password is provided
      }

      // If there is an error, stop the login process
      if (hasError) return;

      try {
        // Call Vuex action to handle login
        const credentials = {
          email: this.email,
          password: this.password,
        };
        this.loginRes = await this.loginUser(credentials);

        // Check if password needs to be reset
        if (this.loginRes.data.profile.verify_password === 0) {
          // Show "Set New Password" form and prevent further login actions
          this.userInactive = true;
          return; // Stop further execution
        }

        // If the user is authenticated, proceed with login success
        if (this.isAuthenticated) {
          this.$toast.open({
            message: 'Login berhasil!',
            type: 'success',
            position: 'top'
          });
          this.$router.push('/data-master/user'); // Redirect to main route
        }
      } catch (error) {
        this.$toast.open({
          message: 'Error!',
          type: 'error',
          position: 'top'
        });
        this.passwordError = 'Login gagal, periksa kembali email dan password anda';
      }
    },

    async setNewPassword() {
      
      if (this.newPassword === '' || this.confirmPassword === '') {
        this.setNewPasswordError = "Password tidak boleh kosong"
        return
      }
      try {
        const credentials = {
        auth: this.loginRes,
        old_password: this.password,
        new_password: this.newPassword,
        verify_new_password: this.confirmPassword
      };
      
      await this.changePassword(credentials);
      this.$toast.open({
        message: 'Login berhasil!',
        type: 'success',
        position: 'top'
      });
      this.$router.push('/data-master/user'); // Redirect to main route
      } catch (error) {
        this.setNewPasswordError = error.response.data.message;
        this.$toast.open({
          message: 'Gagal!',
          type: 'error',
          position: 'top'
        });
      }
    }
  }
}
</script>

<style scoped>
html, body {
  height: 100%;
  margin: 0;
  overflow: hidden; /* Prevent scrolling */
}

.login-container {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
}

.login-left,
.login-right {
  flex: 1 1 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
}

.login-left-content {
  max-width: 400px;
  width: 100%;
}

.login-left h2 {
  font-size: 28px;
  font-weight: bold;
  color: #000;
  margin-bottom: 10px;
}

.login-left p {
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 30px;
}

.login-form .form-label {
  font-size: 14px;
  color: #559BF0;
  margin-bottom: 5px;
}

.login-form .form-control {
  border: none;
  box-shadow: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  padding: 10px 15px;
  font-size: 14px;
  height: auto;
  transition: border-bottom-color 0.3s ease;
}

.login-form .form-control:focus {
  border-bottom: 2px solid #559BF0;
  outline: none;
}

.login-form .input-group {
  position: relative;
}

.login-form .input-group-text {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;
}

.login-form .input-group-text i {
  color: #6c757d;
}

.login-form .show-pass {
  z-index: 100;
}

.login-form .btn {
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 25px;
  background-color: #559BF0;
  color: #fff;
  margin-top: 20px;
}

.login-right {
  background-image: url(@/assets/banner/login-banner.png);
  background-size: cover;
  background-position: center;
}

.login-right-content {
  text-align: center;
  color: #ffffff;
}

img.img-fluid {
  max-width: 100%;
  height: auto;
}

.login-form .forgot {
  color: #559BF0;
  font-size: 14px;
  text-decoration: none;
}

.login-form .is-invalid {
  border-bottom: 2px solid red;
  box-shadow: none;
}

.login-form .is-invalid:focus {
  border-bottom: 2px solid red;
  box-shadow: none;
}

.invalid-input {
  color: red;
  font-size: 12px;
  margin-top: 5px;
  position: absolute;
}

.logo-kalbe {
  position: absolute;
  left: 20px;
  top: 20px;
  width: 180px;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .login-container {
    margin-top: 100px;
  }
  .login-container .login-headline {
    font-size: 50px;
    text-transform: uppercase;
  }
  .login-left, .login-right {
    flex: 1 1 100%;
    padding: 20px;
  }

  .login-left-content {
    max-width: 100%;
  }

  .login-left h2 {
    font-size: 24px;
  }

  .login-left p {
    font-size: 14px;
  }

  .login-form .btn {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .login-left, .login-right {
    padding: 10px;
  }

  .login-left h2 {
    font-size: 20px;
  }

  .login-left p {
    font-size: 12px;
  }

  .login-form .form-control {
    padding: 8px 10px;
  }

  .login-form .btn {
    height: 40px;
    font-size: 14px;
  }

  .login-right {
    display: none; /* Hide the right section on very small screens */
  }
}
.small.forgot {
  font-size: 12px;
}

.form-control.password-input {
  background-position: right calc(2.375em + .1875rem) center;
}
.form-control.set-password {
  background-position: right calc(2.375em + .1875rem) center;
}
</style>
