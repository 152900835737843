import { apiGetAuth, apiDeleteAuth, apiPostAuthWithImage } from '@/utils/api'; 
import { API_MASTER, API_AUTH } from '@/utils/api-url'; 

const state = { 
  settlement_operationals: [], 
  settlement_operational: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_SETTLEMENT_OPERATIONALS(state, settlement_operationals) { 
    state.settlement_operationals = settlement_operationals; 
  }, 
  SET_SETTLEMENT_OPERATIONAL(state, settlement_operational) { 
    state.settlement_operational = settlement_operational; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getSettlementOperationals({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.SETTLEMENT_OPERATIONAL}?limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_SETTLEMENT_OPERATIONALS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createSettlementOperational({ commit }, payload) { 
    
    commit('SET_LOADING', true); 
    try { 
      console.log(API_MASTER.CREATE_SETTLEMENT_OPERATIONALS);
      
      const response = await apiPostAuthWithImage(API_MASTER.CREATE_SETTLEMENT_OPERATIONAL, payload); 
      console.log(response);
      
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateSettlementOperational({ commit }, payload) { 
    commit('SET_LOADING', true); 
    console.log(payload);
    
    try { 
      const response = await apiPostAuthWithImage(API_MASTER.UPDATE_SETTLEMENT_OPERATIONAL, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async deleteSettlementOperational({ commit }, payload) { 
    commit('SET_LOADING', true); 
    console.log(API_MASTER.UPDATE_SETTLEMENT_OPERATIONAL);
    
    try { 
      const response = await apiDeleteAuth(`${API_MASTER.UPDATE_SETTLEMENT_OPERATIONAL}?settle_number=${payload.settle_number}`); 
      console.log(response);
      
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getSettlementOperationalById({ commit }, settle_number) { 
    commit('SET_LOADING', true); 
    
    try { 
      const response = await apiGetAuth(`${API_MASTER.DETAIL_SETTLEMENT_OPERATIONAL}?settle_number=${settle_number}`);
      if (response.data.status_code === 200) { 
        return response.data.data 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBudgetOwner({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(API_MASTER.SETTLEMENT_OPERATIONAL_BUDGET); 
      if (response.data.status_code === 200) {
        
        return response.data.data.map(budgetOwner => ({
          id: budgetOwner.name,
          value: budgetOwner.name,
          name: budgetOwner.name // Adjusted to use module_name from response
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getExpenses({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(API_MASTER.SETTLEMENT_OPERATIONAL_EXPENSE+"?type=operational"); 
      if (response.data.status_code === 200) {
        return response.data.data.map(expense => ({
          id: expense.id,
          name: expense.expense_name,
          value: expense.expense_code,
          expense_code: expense.expense_code,
          expense_name: expense.expense_name,
          expense_category_code: expense.expense_category_code,
          expense_category_name: expense.expense_category_name,
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getEventById({ commit }, id ) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.EVENT}/${id}`); 
      if (response.data.status_code === 200) {
        // Return the mapped parent data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getBranches({ commit }) { 
    
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.BRANCHES}`); 
      if (response.data.status_code === 200) {
        // Return the mapped branches data
        return response.data.data.map(branches => ({
          id: branches.id,
          value: branches.id,
          name: branches.name
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getOutlet({ commit }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.BR_OUTLET}?limit=1000&page=1`); 
      if (response.data.status_code === 200) { 
        commit('SET_BR_OUTLETS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getTaxByCodes({ commit }, type) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.TAX}?type=${type}`);
      if (response.data.status_code === 200) {
        // Return the tax codes data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getItems({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.ITEM}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data.map(item => ({
          id: item.item_code,
          value: item.item_code,
          name: item.item_name,
          percentage_badan : item.percentage_badan,
          percentage_perorangan : item.percentage_perorangan
        }));
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getUserById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.USER}/${id}`); 
      if (response.data.status_code === 200) { 
        return response.data.data;
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  setSelectedSettlementOperational({ commit }, poEvent) {
    commit('SET_SETTLEMENT_OPERATIONAL', poEvent);
  }

}; 

const getters = { 
  getSettlementOperationals(state) { 
    return state.settlement_operationals; 
  }, 
  getSettlementOperational(state) { 
    return state.settlement_operational; 
  }, 
  getTotalSettlementOperationals(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
