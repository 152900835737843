import { apiGetAuth, apiPostAuthWithImage, apiPutAuth } from '@/utils/api'; 
import { API_AUTH, API_MASTER } from '@/utils/api-url'; 

const state = { 
  approval_vendors: [], 
  approval_vendor: null, 
  total: 0, 
  loading: false, 
  error: null 
}; 

const mutations = { 
  SET_VENDORS(state, approval_vendors) { 
    state.approval_vendors = approval_vendors; 
  }, 
  SET_VENDOR(state, approval_vendor) { 
    state.approval_vendor = approval_vendor; 
  }, 
  SET_TOTAL(state, total) { 
    state.total = total; 
  }, 
  SET_LOADING(state, isLoading) { 
    state.loading = isLoading; 
  }, 
  SET_ERROR(state, error) { 
    state.error = error; 
  } 
}; 

const actions = { 
  async getVendor({ commit }, { limit, page, search }) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_MASTER.APPROVAL_VENDOR}?limit=${limit}&page=${page}&search=${search}`); 
      if (response.data.status_code === 200) { 
        
        commit('SET_VENDORS', response.data.data.data); 
        commit('SET_TOTAL', response.data.data.total); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async createVendor({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPostAuthWithImage(API_AUTH.CREATE_VENDOR, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async updateVendor({ commit }, payload) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiPutAuth(API_AUTH.UPDATE_VENDOR, payload); 
      if (response.data.status_code === 200) { 
        return response.data.message; 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
      throw error; 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  }, 

  async getVendorById({ commit }, id) { 
    commit('SET_LOADING', true); 
    try { 
      const response = await apiGetAuth(`${API_AUTH.VENDOR}/${id}`); 
      if (response.data.status_code === 200) { 
        commit('SET_VENDOR', response.data.data); 
      } else { 
        throw new Error(response.data.message); 
      } 
    } catch (error) { 
      commit('SET_ERROR', error.message); 
    } finally { 
      commit('SET_LOADING', false); 
    } 
  },

  async getProvinces({ commit }) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.PROVINCE}?limit=100&page=1`);
      if (response.data.status_code === 200) {
        return response.data.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getCities({ commit }, provinsi_code) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.CITY}?limit=100&page=1&provinsi_code=${provinsi_code}`);
      if (response.data.status_code === 200) {
        // Return the mapped cities data
        return response.data.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getDistricts({ commit }, kabupaten_code) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.DISTRICT}?limit=100&page=1&kabupaten_code=${kabupaten_code}`);
      if (response.data.status_code === 200) {
        // Return the mapped districts data
        return response.data.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getVillages({ commit }, kecamatan_code) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_MASTER.VILLAGE}?limit=100&page=1&kecamatan_code=${kecamatan_code}`);
      if (response.data.status_code === 200) {
        // Return the mapped villages data
        return response.data.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getTaxByCodes({ commit }, type) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.TAX}?type=${type}`);
      if (response.data.status_code === 200) {
        // Return the tax codes data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async getBranchesByUser({ commit }) {
    commit('SET_LOADING', true);
    try {
      // Call the API for tax codes with the specified type
      const response = await apiGetAuth(`${API_MASTER.BRANCHES_BY_USER}`);
      if (response.data.status_code === 200) {
        // Return the tax codes data
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getTimelineStatus({ commit }, { agreement_number}) {
    commit('SET_LOADING', true);
    try {
      const response = await apiGetAuth(`${API_AUTH.GET_STATUS_VENDOR}?agreement_number=${agreement_number}`);
      if (response.data.status_code === 200) {
        return response.data.data;
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      commit('SET_ERROR', error.message);
    } finally {
      commit('SET_LOADING', false);
    }
  },

}; 

const getters = { 
  getVendors(state) { 
    return state.approval_vendors; 
  }, 
  getVendor(state) { 
    return state.approval_vendor; 
  }, 
  getTotalVendors(state) { 
    return state.total; 
  }, 
  isLoading(state) { 
    return state.loading; 
  }, 
  getError(state) { 
    return state.error; 
  } 
}; 

export default { 
  namespaced: true, 
  state, 
  mutations, 
  actions, 
  getters 
};
